import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'

import { useRenderHtml, useHighlightedContent } from 'hooks'

import './Tabs.scss'

export default function RoofTileTabs({ roofTiles, onSelect = function () {} }) {
  const useTabContent = roofTile => {
    if (roofTile.customContent) {
      return (
        <TabPanel key={roofTile.id}>
          {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useRenderHtml(useHighlightedContent(roofTile.customContent))
          }
        </TabPanel>
      )
    } else {
      return (
        <TabPanel key={roofTile.id}>
          {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useRenderHtml(useHighlightedContent(roofTile.content))
          }
        </TabPanel>
      )
    }
  }

  const useTabNavigation = roofTile => (
    <Tab key={roofTile.id}>
      <img className="react-tabs__image" src={roofTile.image} alt={roofTile.name} />
      <h4 className="react-tabs__title">{useRenderHtml(useHighlightedContent(roofTile.name))}</h4>
    </Tab>
  )

  return (
    <Tabs onSelect={index => onSelect(roofTiles[index])}>
      <TabList>{roofTiles.map(useTabNavigation)}</TabList>
      {roofTiles.map(useTabContent)}
    </Tabs>
  )
}
