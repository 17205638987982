import { useTranslation } from 'react-i18next'

import useLocalizedImages from 'hooks/localizedImages'

import CorrugatedTileImage from 'assets/images/roof-tiles/corrugated-tile.png'
import FlatTileImage from 'assets/images/roof-tiles/flat-tile.png'
import GeneronTileImage from 'assets/images/roof-tiles/generon.png'
import MedicomfortTileImage from 'assets/images/roof-tiles/medicomfort.png'

import CoppoTileImage from 'assets/images/roof-tiles/danubia-coppo-renova.png'
import SynusTileImage from 'assets/images/roof-tiles/synus.png'
import RundoTileImage from 'assets/images/roof-tiles/rundo.png'
import ZenitTileImage from 'assets/images/roof-tiles/zenit.png'

// import CoppoVentilationTileImage from 'assets/images/structural-width/fedesi-szelesseg.png'
// import SynusVentilationTileImage from 'assets/images/structural-width/szerkezeti-szelesseg-synus.png'
import RundoVentilationTileImage from 'assets/images/structural-width/szerkezeti-szelesseg-rundo-a.png'
import ZenitVentilationTileImage from 'assets/images/structural-width/szerkezeti-szelesseg-zenit-a.png'

import gutterImage from 'assets/images/gutter-structure.png'
import FerenczyAndras from '../assets/images/consultants/ferenczy-andras.png'
import NagyIgnaczZsoka from '../assets/images/consultants/nagy-ignacz-zsoka.png'

export function usePageContents() {
  const { t } = useTranslation()
  const {
    CoverageLengthImage,
    CoppoStructuralWidthImage,
    SynusStructuralWidthImage
  } = useLocalizedImages()

  const nodeDrawingsPageContent = [
    {
      id: 1,
      image: CorrugatedTileImage,
      name: t('pages.nodeDrawings.contents.corrugatedTiles.name'),
      content: t('pages.nodeDrawings.contents.corrugatedTiles.content')
    },
    {
      id: 2,
      image: FlatTileImage,
      name: t('pages.nodeDrawings.contents.flatTiles.name'),
      content: t('pages.nodeDrawings.contents.flatTiles.content')
    },
    {
      id: 3,
      image: GeneronTileImage,
      name: t('pages.nodeDrawings.contents.generon.name'),
      content: t('pages.nodeDrawings.contents.generon.content')
    },
    {
      id: 4,
      image: MedicomfortTileImage,
      name: t('pages.nodeDrawings.contents.medicomfort.name'),
      content: t('pages.nodeDrawings.contents.medicomfort.content')
    }
  ]

  const generonPageContent = `<div class="d-flex align-items-center">
        ${t('pages.generon.content.0')}
        <img alt="generon tile" class="ml-20px mr-30px" src='${GeneronTileImage}' />
        ${t('pages.generon.content.1')}
  </div>`

  const coverageLengthContent = [
    [
      {
        id: 1,
        image: CorrugatedTileImage,
        name: t('pages.coverageLength.contents.corrugatedTiles.name'),
        content: t('pages.coverageLength.contents.corrugatedTiles.content')
      },
      {
        id: 2,
        image: FlatTileImage,
        name: t('pages.coverageLength.contents.flatTiles.name'),
        content: t('pages.coverageLength.contents.flatTiles.content')
      }
    ],
    `${t('pages.coverageLength.contents.description.0')}
      <img class="ml-40px" src="${CoverageLengthImage}" alt="${t('pages.coverageLength.imageAlt.coverageLength')}" />
      ${t('pages.coverageLength.contents.description.1')}`
  ]

  const structuralWidthPageContent = [
    {
      id: 1,
      image: CoppoTileImage,
      name: 'Coppo',
      content: `<img src="${CoppoStructuralWidthImage}" alt="${t('pages.structuralWidth.contents.coppo.imgalt')}" />`
    },
    {
      id: 2,
      image: SynusTileImage,
      name: 'Synus',
      content: `<img src="${SynusStructuralWidthImage}" alt="${t('pages.structuralWidth.contents.synus.imgalt')}" />`
    },
    {
      id: 3,
      image: RundoTileImage,
      name: 'Rundo',
      content: `<img src="${RundoVentilationTileImage}" alt="${t('pages.structuralWidth.contents.rundo.imgalt')}" />`
    },
    {
      id: 4,
      image: ZenitTileImage,
      name: 'Zenit Max',
      content: `<img src="${ZenitVentilationTileImage}" alt="${t('pages.structuralWidth.contents.zenit.imgalt')}" />`
    }
  ]

  const performanceDeclarationsPageContent = t('pages.performanceDeclarations.content')

  const baseTilesPageContent = [
    {
      id: 1,
      image: CoppoTileImage,
      name: 'Coppo, Renova Plus',
      tile: 'coppo',
      content: t('pages.baseTilesDimensions.contents.coppo.content')
    },
    {
      id: 2,
      image: SynusTileImage,
      name: 'Synus',
      tile: 'synus',
      content: t('pages.baseTilesDimensions.contents.synus.content')
    },
    {
      id: 3,
      image: RundoTileImage,
      name: 'Rundo',
      tile: 'rundo',
      content: t('pages.baseTilesDimensions.contents.rundo.content')
    },
    {
      id: 4,
      image: ZenitTileImage,
      name: 'Zenit Max',
      tile: 'zenit',
      content: t('pages.baseTilesDimensions.contents.zenit.content')
    }
  ]

  const ventilationTilesPageContent = [
    {
      id: 1,
      image: CoppoTileImage,
      name: t('pages.ventilationTilesDimensions.contents.coppo.name'),
      content: `<div class="d-flex"><div class="mr-30px">${t(
        'pages.ventilationTilesDimensions.contents.coppo.content'
      )}</div><img class="mt-10px" src="${CoppoStructuralWidthImage}" width="330" height="330" alt="coppo szellőzőcserép ábra" /></div>`
    },
    {
      id: 2,
      image: SynusTileImage,
      name: t('pages.ventilationTilesDimensions.contents.synus.name'),
      content: `<div class="d-flex"><div class="mr-30px">${t(
        'pages.ventilationTilesDimensions.contents.synus.content'
      )}</div><img class="mt-10px" src="${SynusStructuralWidthImage}" width="330" height="330" alt="synus szellőzőcserép ábra"/></div>`
    },
    {
      id: 3,
      image: RundoTileImage,
      name: t('pages.ventilationTilesDimensions.contents.rundo.name'),
      content: `<div class="d-flex"><div class="mr-30px">${t(
        'pages.ventilationTilesDimensions.contents.rundo.content'
      )}</div><img class="mt-10px" src="${RundoVentilationTileImage}" width="330" height="330" alt="rundo szellőzőcserép ábra"/></div>`
    },
    {
      id: 4,
      image: ZenitTileImage,
      name: t('pages.ventilationTilesDimensions.contents.zenit.name'),
      content: `<div class="d-flex"><div class="mr-30px">${t(
        'pages.ventilationTilesDimensions.contents.zenit.content'
      )}</div><img class="mt-10px" src="${ZenitVentilationTileImage}" width="330" height="330" alt="zenit szellőzőcserép ábra"/></div>`
    }
  ]

  const foilSelectorPageContent = t('pages.foilSelector.contents.description')

  const gutterPageContent = [
    t('pages.gutter.contents.lead'),
    [
      {
        id: 1,
        title: t('pages.gutter.contents.accordion.0.title'),
        content: t('pages.gutter.contents.accordion.0.content')
      },
      {
        id: 2,
        title: t('pages.gutter.contents.accordion.1.title'),
        content: t('pages.gutter.contents.accordion.1.content')
      },
      {
        id: 3,
        title: t('pages.gutter.contents.accordion.2.title'),
        content: t('pages.gutter.contents.accordion.2.content')
      },
      {
        id: 4,
        title: t('pages.gutter.contents.accordion.3.title'),
        content: t('pages.gutter.contents.accordion.3.content')
      },
      {
        id: 5,
        title: t('pages.gutter.contents.accordion.4.title'),
        content: t('pages.gutter.contents.accordion.4.content')
      }
    ],
    `<h2>${t('pages.gutter.contents.trail.title')}</h2><div class="d-flex justify-content-between">${t(
      'pages.gutter.contents.trail.content'
    )}<img src="${gutterImage}" alt="ereszcsatorna-rendszer felépítése ábra" width="305" height="400"/></div>`
  ]

  const medicomfortPageContent = [
    t('pages.medicomfort.contents.lead'),
    [
      {
        id: 1,
        title: t('pages.medicomfort.contents.accordion.0.title'),
        content: t('pages.medicomfort.contents.accordion.0.content')
      },
      {
        id: 2,
        title: t('pages.medicomfort.contents.accordion.1.title'),
        content: t('pages.medicomfort.contents.accordion.1.content')
      }
    ]
  ]

  const consultantsPageContent = [
    {
      id: 1,
      photo: `<img src="${FerenczyAndras}" alt="Ferenczy András">`,
      content: t('pages.consultants.contents.ferenczyAndras')
    },
    {
      id: 2,
      photo: `<img src="${NagyIgnaczZsoka}" alt="Nagy-Ignácz Zsóka">`,
      content: t('pages.consultants.contents.nagyIgnaczZsoka')
    }
  ]

  return {
    nodeDrawingsPageContent,
    generonPageContent,
    coverageLengthContent,
    structuralWidthPageContent,
    performanceDeclarationsPageContent,
    baseTilesPageContent,
    ventilationTilesPageContent,
    foilSelectorPageContent,
    gutterPageContent,
    medicomfortPageContent,
    consultantsPageContent
  }
}
