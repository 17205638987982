import React from 'react'

export const initialState = {
  s: ''
}

export const stateSearchContext = React.createContext(initialState)
export const setSearchContext = React.createContext(undefined)

export function useSearchState() {
  return [React.useContext(stateSearchContext), React.useContext(setSearchContext)]
}

export function useSetSearch() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState)
}
