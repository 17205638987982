import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import { usePageContents } from 'hooks'
import httpClient from 'services/ApiService.js'

import PageTitle from 'components/Pages/PageTitle.js'
import RoofTileTabs from 'components/Tabs/Tabs.js'
import Carousel from 'components/Carousel/Carousel.js'

function GalleryCarousel({ galleryImages, isLoading, error }) {
  if (error) {
    return null
  } else if (!galleryImages.length || isLoading) {
    return <div className="carousel-placeholder"></div>
  } else {
    return <Carousel slides={galleryImages} width="100%" />
  }
}

export default function BaseTilesDimensions() {
  const { baseTilesPageContent } = usePageContents()
  const [galleryImages, setGalleryImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedTile, setSelectedTile] = useState(baseTilesPageContent[0])

  useEffect(() => {
    const getGalleryImages = async () => {
      setGalleryImages([])

      try {
        const { data: response } = await httpClient.get('gallery', '', {
          includes: selectedTile.tile,
          lang: i18n.language
        })
        setGalleryImages(response.data)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }
    getGalleryImages()
  }, [selectedTile.tile])

  const { t } = useTranslation()

  return (
    <section>
      <PageTitle title={t('pages.baseTilesDimensions.title')} />
      <RoofTileTabs roofTiles={baseTilesPageContent} onSelect={selectedTile => setSelectedTile(selectedTile)} />
      <GalleryCarousel galleryImages={galleryImages} isLoading={isLoading} error={error} />
    </section>
  )
}
