import { useState } from 'react'

import './Accordion.scss'

import AccordionItem from './AccordionItem.js'

export default function Accordion({ items }) {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleOpen = index => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className="accordion">
      {items.map(item => (
        <AccordionItem item={item} key={item.id} isOpen={openIndex === item.id} onToggle={() => toggleOpen(item.id)} />
      ))}
    </div>
  )
}
