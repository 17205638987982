import { useTranslation } from 'react-i18next'
import ContentNotFoundIcon from 'assets/images/error.svg'

export default function ContentNotFound() {
  const { t } = useTranslation()

  return (
    <div className="not-found">
      <img src={ContentNotFoundIcon} alt="hiba ikon" />
      <h2>{t('contentNotFound')}</h2>
    </div>
  )
}
