import httpClient from 'services/ApiService.js'

export default function useFiles() {
  async function getFile(resource, contentType = 'application/x-www-form-urlencoded') {
    const file = await httpClient.request(
      'get',
      resource,
      {},
      {
        headers: {
          Accept: contentType,
          'Content-Type': contentType
        },
        responseType: 'arraybuffer',
        transformResponse: [
          function (data) {
            return new Blob([data], { type: contentType })
          }
        ]
      }
    )

    return file.data
  }

  function downloadFile(fileBlob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(fileBlob, fileName)
    } else {
      var elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(fileBlob)
      elem.download = fileName
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }
  }

  function getFileNameFromUrl(url) {
    const paths = url.split('/').filter(entry => entry !== '')
    const fileName = paths[paths.length - 1]

    return fileName
  }

  function setupFileDownloadEventListener() {
    const links = document.getElementsByTagName('a')

    async function handleClick(event) {
      const element = event.target

      if (!element.href) return

      const ALLOWED_FILE_TYPES = ['dxf', 'dwg']
      const fileExtension = getFileNameFromUrl(element.href).split('.')[1]

      if (ALLOWED_FILE_TYPES.includes(fileExtension)) {
        event.preventDefault()
        const file = await getFile(element.href)
        downloadFile(file, getFileNameFromUrl(element.href))
      }
    }

    Array.from(links).forEach(link => link.addEventListener('click', handleClick))
  }

  return {
    getFile,
    downloadFile,
    getFileNameFromUrl,
    setupFileDownloadEventListener
  }
}
