import { useSearchState, useFilteredNavigation } from 'hooks'
import NavigationListItem from './NavigationListItem.js'

export default function NavigationList({ menuList }) {
  const [state] = useSearchState()
  const filteredNavigation = useFilteredNavigation(menuList)

  const isDisabled = menuItem => {
    if (!state.s) {
      return false
    }

    return !menuItem?.hasMatch
  }

  return (
    <ul className="navigation-list" aria-label="category-navigation">
      {filteredNavigation.map(menuItem => (
        <NavigationListItem menuItem={menuItem} key={menuItem.id} disabled={isDisabled(menuItem)} />
      ))}
    </ul>
  )
}
