import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './Search.module.scss'

import { useSearchState } from 'hooks/searchState.js'
import { useFilteredNavigation } from 'hooks/filteredNavigation.js'

import SearchIcon from 'assets/images/search.svg'
import DeleteIcon from 'assets/images/delete.svg'
import useRoutes from 'hooks/routes'

export default function Search() {
  const { t } = useTranslation()
  const { menuList } = useRoutes()

  /* eslint-disable-next-line no-unused-vars */
  const [searchState, setSearchState] = useSearchState()
  const [hasSearchQuery, setHasSearchQuery] = useState(false)
  const filteredNavigation = useFilteredNavigation(menuList)

  const onSearch = event => {
    event.preventDefault()
    setSearchState({ s: event.target.search.value })
  }

  const onReset = () => {
    setSearchState({ s: '' })
    setHasSearchQuery(false)
  }

  const onFieldChange = event => setHasSearchQuery(!!event.target.value.length)

  return (
    <div className={styles.searchPanel}>
      <h2>{t('searchBox.title')}</h2>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="form-group">
          <label htmlFor="search" className="sr-only">
            {t('searchBox.search')}
          </label>
          <input
            id="search"
            type="text"
            name="search"
            className={classNames(styles.field, 'form-control')}
            onChange={onFieldChange}
          />
          <button type="submit" className={styles.submit}>
            <img src={SearchIcon} alt={t('searchBox.Search')} />
          </button>
          <button type="reset" className={styles.reset} disabled={!hasSearchQuery}>
            <img src={DeleteIcon} alt={t('searchBox.reset')} />
          </button>
        </div>
        {!hasSearchQuery || filteredNavigation.filter(item => item.hasMatch).length ? (
          <p>
            <strong>{t('searchBox.results')}</strong>
          </p>
        ) : (
          <p>
            <strong>{t('searchBox.noResults')}</strong>
          </p>
        )}
      </form>
    </div>
  )
}
