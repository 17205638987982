import { useTranslation } from 'react-i18next'
import { useRenderHtml, useHighlightedContent, usePageContents } from 'hooks'

import PageTitle from 'components/Pages/PageTitle.js'

export default function Consultants() {
  const { t } = useTranslation()

  const { consultantsPageContent } = usePageContents()

  const useConsultant = (consultant, index) => (
    <div className="consultant" key={index}>
      {useRenderHtml([consultant.photo, useHighlightedContent(t(consultant.content))])}
    </div>
  )

  return (
    <article className="consultant-page">
      <PageTitle title={t('pages.consultants.title')} />
      <div className="consultants">{consultantsPageContent.map(useConsultant)}</div>
    </article>
  )
}
