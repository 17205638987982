import { useSearchState } from 'hooks/searchState.js'

export function useFilteredNavigation(navigation) {
  const [state] = useSearchState()

  if (!state.s) {
    return navigation
  }

  const regex = new RegExp(state.s, 'gi')

  function surroundInElement(el) {
    if (!/^(script|style)$/.test(el.tagName)) {
      let child = el.lastChild
      while (child) {
        if (child.nodeType === 1) {
          if (surroundInElement(child, regex)) {
            return true
          }
        } else if (child.nodeType === 3) {
          if (regex.exec(child.data)) {
            return true
          }
        }

        child = child.previousSibling
      }

      return false
    }
  }

  function hasMatch(content) {
    const shadowDom = document.createElement('html')
    shadowDom.innerHTML = content

    return surroundInElement(shadowDom.getElementsByTagName('body')[0])
  }

  function filterNav(nav) {
    if (typeof nav !== 'object' && !Array.isArray(nav)) {
      return {
        content: nav,
        hasMatch: hasMatch(nav)
      }
    }

    if (Array.isArray(nav)) {
      return nav.map(i => {
        const results = filterNav(i)
        return {
          ...i,
          content: results,
          hasMatch: results?.hasMatch || (Array.isArray(results) && !!results?.filter(j => j?.hasMatch).length) || false
        }
      })
    }

    let results = { ...nav }
    if ('content' in nav) {
      const contentResults = filterNav(nav.content)
      results = {
        ...results,
        content: contentResults,
        hasMatch:
          contentResults?.hasMatch ||
          (Array.isArray(contentResults) && !!contentResults?.filter(j => j?.hasMatch).length) ||
          false
      }
    }

    if ('name' in nav) {
      const titleResults = filterNav(nav.name)
      results = {
        ...results,
        title: titleResults,
        hasMatch:
          results.hasMatch ||
          titleResults?.hasMatch ||
          (Array.isArray(titleResults) && !!titleResults?.filter(j => j?.hasMatch).length) ||
          false
      }
    }

    return results
  }

  return filterNav(navigation)
}
