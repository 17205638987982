import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function NavigationListItem({ menuItem, disabled }) {
  const { t } = useTranslation()

  return (
    <li className="navigation-list-item">
      <NavLink to={t(`pages.${menuItem.name}.path`)} disabled={disabled}>
        <div className="navigation-list-item-icon">
          <img src={require(`assets/images/navigation-icons/${menuItem.name}.svg`)} alt={menuItem.title} />
        </div>
        <h3 className="navigation-list-item-title">{menuItem.title}</h3>
      </NavLink>
    </li>
  )
}
