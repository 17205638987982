import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useFiles from 'hooks/files'
import styles from './DocumentBanner.module.scss'

export default function DocumentBanner({ title, image, background, link, download }) {
  const { setupFileDownloadEventListener } = useFiles()
  const { t } = useTranslation()

  useEffect(() => {
    setupFileDownloadEventListener()
  }, [setupFileDownloadEventListener])

  return (
    <div className={styles.Banner} style={{ backgroundImage: `url(${background})` }}>
      <div className={styles.BannerContent}>
        <h1 className={styles.BannerTitle}>{title}</h1>
        <a href={link} className={styles.BannerButton} target="_blank" download={download} rel="noreferrer">
          {t('prospectDownload')}
        </a>
      </div>
      <img className={styles.BannerImage} src={image} alt={title} />
    </div>
  )
}
