import { useTranslation } from 'react-i18next'

import PageTitle from 'components/Pages/PageTitle.js'
import RoofTileTabs from 'components/Tabs/Tabs.js'

import { usePageContents } from '../hooks'

export default function VentilationTilesDimensions() {
  const { t } = useTranslation()
  const { ventilationTilesPageContent } = usePageContents()

  return (
    <article>
      <PageTitle title={t('pages.ventilationTilesDimensions.title')} />
      <RoofTileTabs roofTiles={ventilationTilesPageContent} />
    </article>
  )
}
