import { useSearchState } from 'hooks/searchState.js'

export function useHighlightedContent(content) {
  const [state] = useSearchState()

  if (!state.s) {
    return content
  }

  function createSpan(matchedTextNode) {
    const el = document.createElement('span')
    el.classList.add('highlight')
    el.appendChild(matchedTextNode)
    return el
  }

  function surroundMatchingText(textNode, regex, callback) {
    const parent = textNode.parentNode
    let result, surroundingNode, matchedTextNode, matchLength, matchedText
    while (textNode && (result = regex.exec(textNode.data))) {
      matchedTextNode = textNode.splitText(result.index)
      matchedText = result[0]
      matchLength = matchedText.length
      textNode = matchedTextNode.length > matchLength ? matchedTextNode.splitText(matchLength) : null
      regex.lastIndex = 0
      surroundingNode = callback(matchedTextNode.cloneNode(true))
      parent.insertBefore(surroundingNode, matchedTextNode)
      parent.removeChild(matchedTextNode)
    }
  }

  function surroundInElement(el, regex, callback) {
    if (!/^(script|style)$/.test(el.tagName)) {
      let child = el.lastChild
      while (child) {
        if (child.nodeType === 1) {
          surroundInElement(child, regex, callback)
        } else if (child.nodeType === 3) {
          surroundMatchingText(child, regex, callback)
        }
        child = child.previousSibling
      }
    }
  }

  const shadowDom = document.createElement('html')
  shadowDom.innerHTML = content

  surroundInElement(shadowDom.getElementsByTagName('body')[0], new RegExp(state.s, 'gi'), createSpan)

  return shadowDom.getElementsByTagName('body')[0].innerHTML
}
