import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useFiles from 'hooks/files'
import { usePageContents } from 'hooks'

import PageTitle from 'components/Pages/PageTitle.js'
import PageContent from 'components/Pages/PageContent.js'

export default function PerformanceDeclarations() {
  const { setupFileDownloadEventListener } = useFiles()
  const { t } = useTranslation()
  const { performanceDeclarationsPageContent } = usePageContents()

  useEffect(() => {
    setupFileDownloadEventListener()
  })

  return (
    <section>
      <PageTitle title={t('pages.performanceDeclarations.title')} />
      <PageContent content={performanceDeclarationsPageContent} />
    </section>
  )
}
