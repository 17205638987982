import styles from 'components/Publications/PublicationList.module.scss'

export default function PublicationListSkeletonLoader() {
  return (
    <div className={styles.List}>
      <div className={styles.ListItemSkeleton}></div>
      <div className={styles.ListItemSkeleton}></div>
      <div className={styles.ListItemSkeleton}></div>
      <div className={styles.ListItemSkeleton}></div>
      <div className={styles.ListItemSkeleton}></div>
    </div>
  )
}
