import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import httpClient from 'services/ApiService.js'

import PageTitle from 'components/Pages/PageTitle.js'
import PublicationList from 'components/Publications/PublicationList.js'
import PublicationListSkeletonLoader from 'components/Publications/PublicationListSkeletonLoader.js'
import ContentNotFound from 'components/NotFound/ContentNotFound.js'

export default function Publications() {
  const [publications, setPublications] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    const getPublications = async () => {
      try {
        const { data: response } = await httpClient.get('publications', '',{language: i18n.language})
        setPublications(response.data)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }
    getPublications()
  }, [])

  if (error) {
    return <ContentNotFound />
  }

  return (
    <article>
      <PageTitle title={t('pages.publications.title')} />
      {isLoading ? <PublicationListSkeletonLoader /> : <PublicationList publications={publications} />}
    </article>
  )
}
