import { useTranslation } from 'react-i18next'

import { usePageContents } from 'hooks'

import PageTitle from 'components/Pages/PageTitle.js'
import PageContent from 'components/Pages/PageContent.js'
import RoofTileTabs from 'components/Tabs/Tabs.js'

export default function CoverageLength() {
  const { t } = useTranslation()
  const { coverageLengthContent } = usePageContents()

  return (
    <div>
      <PageTitle title={t('pages.coverageLength.title')} />
      <RoofTileTabs roofTiles={coverageLengthContent[0]} />
      <PageContent content={coverageLengthContent[1]} />
    </div>
  )
}
