import NavigationList from './NavigationList.js'
import './Navigation.scss'
import useRoutes from 'hooks/routes.js'

export default function Navigation() {
  const { menuList } = useRoutes()

  return (
    <div className="navigation">
      <NavigationList menuList={menuList} />
    </div>
  )
}
