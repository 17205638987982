// https://www.basefactor.com/global-state-with-react

import React from 'react'
import { stateSearchContext, setSearchContext, useSetSearch } from 'hooks/searchState.js'

export default function SearchStateProvider({ children }) {
  const [searchState, setSearchState] = useSetSearch()

  return (
    <stateSearchContext.Provider value={searchState}>
      <setSearchContext.Provider value={setSearchState}>{children}</setSearchContext.Provider>
    </stateSearchContext.Provider>
  )
}
