import { useEffect } from 'react'
import useFiles from 'hooks/files'
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg'

import styles from './PublicationList.module.scss'

export default function PublicationList({ publications }) {
  const { setupFileDownloadEventListener } = useFiles()

  useEffect(() => {
    setupFileDownloadEventListener()
  }, [setupFileDownloadEventListener])

  return (
    <ul className={styles.List}>
      {publications.map(publication => (
        <li className={styles.ListItem} key={publication.id}>
          <img className={styles.Image} src={publication.image} alt={publication.title} />
          <a
            href={publication.file || publication.embed || publication.url}
            className={styles.DownloadButton}
            target="_blank"
            download={publication.file}
            rel="noreferrer">
            <DownloadIcon />
          </a>
          <h4 className={styles.Title}>{publication.title}</h4>
        </li>
      ))}
    </ul>
  )
}
