import { useTranslation } from 'react-i18next'

import { useHighlightedContent, useRenderHtml } from 'hooks'

import PageTitle from 'components/Pages/PageTitle.js'

export default function PrivacyPolicy() {
  const { t } = useTranslation()
  return (
    <div>
      <PageTitle title={t('pages.privacyPolicy.title')} />
      {useRenderHtml(useHighlightedContent(t('pages.privacyPolicy.content')))}
    </div>
  )
}
