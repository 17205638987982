import { useTranslation } from 'react-i18next'

import PageTitle from 'components/Pages/PageTitle.js'
import PageContent from 'components/Pages/PageContent.js'

import { usePageContents } from 'hooks'

export default function Generon() {
  const { t } = useTranslation()
  const { generonPageContent } = usePageContents()

  return (
    <article className="generon-page">
      <PageTitle title={t('pages.generon.title')} />
      <PageContent content={generonPageContent} />
    </article>
  )
}
