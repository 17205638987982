import { useTranslation } from 'react-i18next'

import NotFoundIcon from 'assets/images/404.svg'

export default function PageNotFound() {
  const { t } = useTranslation()

  return (
    <div className="page-404">
      <img src={NotFoundIcon} alt="404 ikon" />
      <h1 className="h2">{t('pageNotFound')}</h1>
    </div>
  )
}
