import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { ReactComponent as BackToTopIcon } from 'assets/images/back-to-top.svg'

export default function BackToTop() {
  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    window.document.addEventListener('scroll', () => {
      setIsHidden(window.document.documentElement.scrollTop < 80)
    })
  }, [])

  function scrollToTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
    <div onClick={scrollToTop} className={classNames('navigation-back-to-top', { hidden: isHidden })}>
      <BackToTopIcon />
    </div>
  )
}
