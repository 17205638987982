import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SearchStateProvider from 'context/SearchContext.js'

import 'assets/styles/main.scss'
import './App.scss'

import InfoBar from './components/InfoBar/InfoBar.js'
import Navigation from 'components/Navigation/Navigation.js'
import BannerSlider from 'components/Banner/BannerSlider/BannerSlider.js'
import Search from 'components/Search/Search.js'
import BackToTop from 'components/Navigation/BackToTop.js'

import Home from 'pages/Home.js'
import PrivacyPolicy from 'pages/PrivacyPolicy.js'
import PageNotFound from 'pages/PageNotFound.js'
import useRoutes from 'hooks/routes'

function App() {
  const { t, i18n } = useTranslation()
  const { menuList } = useRoutes()

  useEffect(() => {
    const path = window.location.pathname.replace(/^\/+/, '').split('/')

    if (!path[0].length) {
      if (i18n.language !== i18n.options.fallbackLng) {
        i18n.changeLanguage(i18n.options.fallbackLng)
      }
    } else if (i18n.languages.includes(path[0]) && i18n.language !== path[0]) {
      i18n.changeLanguage(path[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchStateProvider>
      <div className="app">
        <div className="app-container">
          <InfoBar />
          <Navigation />
          <div className="banners-and-search">
            <BannerSlider />
            <Search />
          </div>
          <div className="app-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/en" element={<Home />} />
              <Route path={t('pages.privacyPolicy.path')} element={<PrivacyPolicy />} />
              {menuList.map(({ component, name }, key) => (
                <Route path={t(`pages.${name}.path`)} element={component} key={key} />
              ))}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <BackToTop />
        </div>
      </div>
    </SearchStateProvider>
  )
}

export default App
