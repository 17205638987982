import classNames from 'classnames'

import { useRenderHtml, useHighlightedContent } from 'hooks'

export default function AccordionItem({ item, isOpen, onToggle }) {
  return (
    <div className="accordion-item">
      <h3 onClick={onToggle} className="accordion-header">
        <button aria-expanded={isOpen} className={classNames('accordion-button', { collapsed: !isOpen })}>
          <span>{useRenderHtml(useHighlightedContent(item.title))}</span>
        </button>
      </h3>
      <div className={isOpen ? 'accordion-collapse show' : 'accordion-collapse'}>
        <div className="accordion-body">{useRenderHtml(useHighlightedContent(item.content))}</div>
      </div>
    </div>
  )
}
