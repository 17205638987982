import { useTranslation } from 'react-i18next'

import { usePageContents } from 'hooks'

import PageTitle from 'components/Pages/PageTitle.js'
import RoofTileTabs from 'components/Tabs/Tabs.js'

export default function StructuralWidth() {
  const { t } = useTranslation()
  const { structuralWidthPageContent } = usePageContents()

  return (
    <article>
      <PageTitle title={t('pages.structuralWidth.title')} />
      <RoofTileTabs roofTiles={structuralWidthPageContent} />
    </article>
  )
}
