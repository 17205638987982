import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'hu',
    lng: 'hu',
    resources: {
      en: {
        translations: require('./locales/en/translations.json')
      },
      hu: {
        translations: require('./locales/hu/translations.json')
      }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    detection: {
      order: ['path']
    }
  })

i18n.languages = ['en', 'hu']

export default i18n
