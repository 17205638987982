import { useTranslation } from 'react-i18next'

import CoverageLengthImageHu from 'assets/images/fedesi-hossz.jpg'
import CoverageLengthImageEn from 'assets/images/fedesi-hossz-en.jpg'

import CoppoStructuralWidthImageHu from 'assets/images/structural-width/fedesi-szelesseg.png'
import CoppoStructuralWidthImageEn from 'assets/images/structural-width/fedesi-szelesseg-en.jpg'
import SynusStructuralWidthImageHu from 'assets/images/structural-width/szerkezeti-szelesseg-synus.png'
import SynusStructuralWidthImageEn from 'assets/images/structural-width/szerkezeti-szelesseg-synus-en.jpg'

export default function useLocalizedImages() {
  const { i18n } = useTranslation()

  const getCoverageLengthImage = (() => i18n.language === 'en' ? CoverageLengthImageEn : CoverageLengthImageHu)

  const getCoppoStructuralWidthImage = (() => i18n.language === 'en' ? CoppoStructuralWidthImageEn : CoppoStructuralWidthImageHu)
  const getSynusStructuralWidthImage = (() => i18n.language === 'en' ? SynusStructuralWidthImageEn : SynusStructuralWidthImageHu)

  return {
    CoverageLengthImage: getCoverageLengthImage(),
    CoppoStructuralWidthImage: getCoppoStructuralWidthImage(),
    SynusStructuralWidthImage: getSynusStructuralWidthImage()
  }
}
