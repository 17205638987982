import { useTranslation } from 'react-i18next'
import styles from './InfoBar.module.scss'

export default function InfoBar() {
  const { t, i18n } = useTranslation()

  if (i18n.language !== 'en') {
    return ''
  }

  return (
    <p className={styles.info}>{t('infoBar')}</p>
  )
}
