import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import PageTitle from 'components/Pages/PageTitle.js'
import RoofTileTabs from 'components/Tabs/Tabs.js'

import useFiles from 'hooks/files'
import { usePageContents } from 'hooks'

export default function NodeDrawings() {
  const [selectedTab, setSelectedTab] = useState(0)
  const { setupFileDownloadEventListener } = useFiles()
  const { t } = useTranslation()
  const { nodeDrawingsPageContent } = usePageContents()

  useEffect(() => {
    setupFileDownloadEventListener()
  }, [selectedTab, setupFileDownloadEventListener])

  return (
    <article>
      <PageTitle title={t(`pages.nodeDrawings.title`)} />
      <p className="mb-30px">
        <Trans>pages.nodeDrawings.info</Trans>
      </p>
      <RoofTileTabs roofTiles={nodeDrawingsPageContent} onSelect={index => setSelectedTab(index)} />
    </article>
  )
}
