import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import classNames from 'classnames'
import httpClient from 'services/ApiService.js'
import ContentNotFound from 'components/NotFound/ContentNotFound.js'
import './BannerSlider.scss'

export default function BannerSlider() {
  const { t } = useTranslation()
  const [banners, setBanners] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [current, setCurrent] = useState(0)
  const SLIDER_SPEED = 10000

  useEffect(() => {
    const getBanners = async () => {
      setIsLoading(true)
      try {
        const { data: response } = await httpClient.get('banners', '',{language: i18n.language})
        setBanners(response.data)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }
    i18n.on('languageChanged', getBanners);
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide()
    }, SLIDER_SPEED)

    return () => clearInterval(interval)
  })

  const nextSlide = () => {
    setCurrent(current === banners.length - 1 ? 0 : current + 1)
  }

  const goToSlide = index => {
    setCurrent(index)
  }

  const bannerSlideClass = index =>
    classNames('banner-slide', {
      active: index === current
    })

  const slidePaginationClass = index =>
    classNames({
      active: index === current
    })

  if (error) {
    return <ContentNotFound />
  } else if (isLoading) {
    return <div className="banners-loader"></div>
  } else {
    return (
      <div className="banners">
        <div className="banner-slider">
          {banners.map((banner, index) => (
            <div
              className={bannerSlideClass(index)}
              key={banner.id}
              style={{ background: `linear-gradient(rgb(16,27,49,0.8), rgb(2,7,17,0.8)), url(${banner.image})`, backgroundSize: 'cover' }}>
              <h3 className="banner-title">{banner.title}</h3>
              <a href={banner.link} className="banner-button" target="_blank" rel="noopener noreferrer">
                {t('illShow')}
              </a>
            </div>
          ))}
          <ul className="slider-pagination">
            {banners.map((banner, index) => (
              <li onClick={() => goToSlide(index)} className={slidePaginationClass(index)} key={index}>
                {index}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}
