import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePageContents } from 'hooks'

import PageTitle from 'components/Pages/PageTitle.js'
import PageContent from 'components/Pages/PageContent.js'

import ExtremeFoilImage from 'assets/images/foils/extreme.png'
import Top270FoilImage from 'assets/images/foils/top-270.png'
import WindPlusFoilImage from 'assets/images/foils/wind-plus.png'
import WindPlus150FoilImage from 'assets/images/foils/wind-plus-150.png'
import WindPlusBlackFoilImage from 'assets/images/foils/wind-plus-black.png'
import Basic140FoilImage from 'assets/images/foils/basic-140.png'
import Basic150FoilImage from 'assets/images/foils/basic-150.png'
import GeneronLongLifeFoilImage from 'assets/images/foils/generon-long-life.png'
import GeneronLongLifePlusFoilImage from 'assets/images/foils/generon-long-life-plus.png'
import ForteFoilImage from 'assets/images/foils/forte.png'
import SilverFoilImage from 'assets/images/foils/silver.png'
import SdFoilImage from 'assets/images/foils/sd.png'
import TecFoilImage from 'assets/images/foils/tec.png'
import Tec150FoilImage from 'assets/images/foils/tec-150.png'
export default function FoilSelector() {
  const [render, setRender] = useState({
    lowAngle: null,
    extremeLow: null,
    stepResistant: null,
    windProof: null,
    attic: null
  })

  const { t } = useTranslation()

  const { foilSelectorPageContent } = usePageContents()

  const foilSelector = [
    {
      id: 'lowAngle',
      q: t('pages.foilSelector.contents.questions.0'),
      render: () => true,
      values: [
        {
          value: true,
          label: t('pages.foilSelector.contents.answers.yes')
        },
        {
          value: false,
          label: t('pages.foilSelector.contents.answers.no')
        }
      ]
    },
    {
      id: 'extremeLow',
      q: t('pages.foilSelector.contents.questions.1'),
      render: () => render.lowAngle,
      values: [
        {
          value: true,
          label: t('pages.foilSelector.contents.answers.yes')
        },
        {
          value: false,
          label: t('pages.foilSelector.contents.answers.no')
        }
      ]
    },
    {
      id: 'stepResistant',
      q: t('pages.foilSelector.contents.questions.2'),
      render: () => render.lowAngle === false,
      values: [
        {
          value: true,
          label: t('pages.foilSelector.contents.answers.yes')
        },
        {
          value: false,
          label: t('pages.foilSelector.contents.answers.no')
        }
      ]
    },
    {
      id: 'windProof',
      q: t('pages.foilSelector.contents.questions.3'),
      render: () => render.lowAngle === false,
      values: [
        {
          value: true,
          label: t('pages.foilSelector.contents.answers.yes')
        },
        {
          value: false,
          label: t('pages.foilSelector.contents.answers.no')
        }
      ]
    },
    {
      id: 'attic',
      q: t('pages.foilSelector.contents.questions.4'),
      render: () => render.lowAngle === false && (render.stepResistant && render.windProof) === false,
      values: [
        {
          value: true,
          label: t('pages.foilSelector.contents.answers.yes')
        },
        {
          value: false,
          label: t('pages.foilSelector.contents.answers.no')
        }
      ]
    }
  ]

  const medifol = {
    extreme: {
      name: 'MediFol EXTREME',
      link: 'https://terranteto.hu/termekek/medifol-extreme/',
      image: ExtremeFoilImage
    },
    top270: {
      name: 'MediFol TOP 270',
      link: 'https://terranteto.hu/termekek/medifol-top-270/',
      image: Top270FoilImage
    },
    windPlus: {
      name: 'MediFol Wind Plus',
      link: 'https://terranteto.hu/termekek/medifol-wind-plus/',
      image: WindPlusFoilImage
    },
    windPlus150: {
      name: 'MediFol Wind Plus 150',
      link: 'https://terranteto.hu/termekek/medifol-wind-plus-150/',
      image: WindPlus150FoilImage
    },
    windPlusBlack: {
      name: 'MediFol Wind Plus Black',
      link: 'https://terranteto.hu/termekek/medifol-wind-plus-black/',
      image: WindPlusBlackFoilImage
    },
    basic140: {
      name: 'MediFol 140',
      link: 'https://terranteto.hu/termekek/medifol-140/',
      image: Basic140FoilImage
    },
    basic150: {
      name: 'MediFol 150',
      link: 'https://terranteto.hu/termekek/medifol-150/',
      image: Basic150FoilImage
    },
    generonLongLife: {
      name: 'MediFol LongLife',
      link: 'https://terranteto.hu/termekek/medifol-longlife/',
      image: GeneronLongLifeFoilImage
    },
    generonLongLifePlus: {
      name: 'MediFol LongLife Plus',
      link: 'https://terranteto.hu/termekek/medifol-longlife-plus/',
      image: GeneronLongLifePlusFoilImage
    },
    forte: {
      name: 'MediFol Generon FORTE',
      link: 'https://terranteto.hu/termekek/medifol-forte/',
      image: ForteFoilImage
    },
    silver: {
      name: 'MediFol Generon SILVER',
      link: 'https://terranteto.hu/termekek/medifol-silver/',
      image: SilverFoilImage
    },
    sd: {
      name: 'MediFol SD',
      link: 'https://terranteto.hu/termekek/medifol-sd/',
      image: SdFoilImage
    },
    tec: {
      name: 'MediFol Generon TEC',
      link: 'https://terranteto.hu/termekek/medifol-tec/',
      image: TecFoilImage
    },
    tec150: {
      name: 'MediFol Generon TEC 150',
      link: 'https://terranteto.hu/termekek/medifol-tec-150/',
      image: Tec150FoilImage
    }
  }

  const resultSet = [
    {
      render: () => render.lowAngle && render.extremeLow === false,
      foils: [medifol.top270, medifol.extreme]
    },
    {
      render: () => render.lowAngle && render.extremeLow,
      foils: [medifol.extreme]
    },
    {
      // render.attic option doesn't matter
      render: () => render.lowAngle === false && render.stepResistant && render.windProof,
      foils: [
        medifol.windPlus,
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.generonLongLifePlus,
        medifol.top270,
        medifol.forte,
        medifol.silver
      ]
    },
    {
      render: () => render.lowAngle === false && render.stepResistant && render.windProof === false && render.attic,
      foils: [
        medifol.windPlus,
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.generonLongLife,
        medifol.generonLongLifePlus,
        medifol.basic140,
        medifol.basic150,
        medifol.top270,
        medifol.forte,
        medifol.silver
      ]
    },
    {
      render: () =>
        render.lowAngle === false && render.stepResistant && render.windProof === false && render.attic === false,
      foils: [
        medifol.windPlus,
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.generonLongLife,
        medifol.generonLongLifePlus,
        medifol.top270,
        medifol.forte,
        medifol.silver
      ]
    },
    {
      render: () => render.lowAngle === false && render.stepResistant === false && render.windProof && render.attic,
      foils: [
        medifol.windPlus,
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.generonLongLifePlus,
        medifol.top270,
        medifol.silver,
        medifol.forte
      ]
    },
    {
      render: () =>
        render.lowAngle === false && render.stepResistant === false && render.windProof && render.attic === false,
      foils: [
        medifol.windPlus,
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.forte,
        medifol.generonLongLifePlus,
        medifol.silver,
        medifol.top270
      ]
    },
    {
      render: () =>
        render.lowAngle === false && render.stepResistant === false && render.windProof === false && render.attic,
      foils: [
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.generonLongLifePlus,
        medifol.basic150,
        medifol.basic140,
        medifol.sd,
        medifol.windPlus,
        medifol.top270,
        medifol.forte,
        medifol.generonLongLife,
        medifol.silver
      ]
    },
    {
      render: () =>
        render.lowAngle === false &&
        render.stepResistant === false &&
        render.windProof === false &&
        render.attic === false,
      foils: [
        medifol.windPlusBlack,
        medifol.windPlus150,
        medifol.generonLongLifePlus,
        medifol.basic150,
        medifol.tec150,
        medifol.basic140,
        medifol.sd,
        medifol.windPlus,
        medifol.top270,
        medifol.forte,
        medifol.generonLongLife,
        medifol.silver,
        medifol.tec
      ]
    }
  ]

  const onSelect = (id, value) => {
    const update = { ...render }
    update[id] = value

    if (id === 'lowAngle' && value === false) {
      update.extremeLow = null
    }

    if (id === 'lowAngle' && value) {
      update.stepResistant = null
      update.windProof = null
      update.attic = null
    }

    if ((id === 'stepResistant' || id === 'windProof') && value) {
      if (id === 'stepResistant' && render.windProof) {
        update.attic = null
      }
      if (id === 'windProof' && render.stepResistant) {
        update.attic = null
      }
    }

    setRender(update)
  }

  const hasResults = () => resultSet.filter(result => result.render()).length > 0

  return (
    <article className="foils-page">
      <PageTitle title={t('pages.foilSelector.title')} />
      <PageContent content={foilSelectorPageContent} />
      <form>
        {foilSelector.map(
          item =>
            item.render() && (
              <div className="form-group form-group--radio" key={item.id}>
                <p>{item.q}</p>
                {item.values.map((val, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name={item.id}
                      value={val.value}
                      onChange={() => onSelect(item.id, val.value)}
                    />
                    <span>{val.label}</span>
                  </label>
                ))}
              </div>
            )
        )}
      </form>
      {hasResults() && (
        <div className="foil-results">
          <p>{t('pages.foilSelector.contents.yourfoil')}</p>
          {resultSet.map(
            (result, index) =>
              result.render() && (
                <div key={index} className="foils">
                  {result.foils.map((foil, foilIndex) => (
                    <a
                      key={foilIndex}
                      href={foil.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="foils__item">
                      <img src={foil.image} alt={foil.name} className="foil__item__image" />
                      <span className="foils__item__name">{foil.name}</span>
                      <span className="foils__item__button">{t('pages.foilSelector.contents.details')}</span>
                    </a>
                  ))}
                </div>
              )
          )}
        </div>
      )}
    </article>
  )
}
