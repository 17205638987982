import { Parser } from 'html-to-react'

export function useRenderHtml(content) {
  const htmlToReactParser = new Parser()

  if (Array.isArray(content)) {
    return htmlToReactParser.parse(content.join(''))
  }

  return htmlToReactParser.parse(content)
}
