import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { ReactComponent as LeftArrowIcon } from 'assets/images/prev.svg'
import { ReactComponent as RightArrowIcon } from 'assets/images/next.svg'

import './Carousel.scss'

export default function Carousel({ slides, width = `330px`, height = `330px` }) {
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    setCurrent(0)
  }, [slides])

  const nextSlide = () => {
    if (current === slides.length - 1) return
    setCurrent(current === slides.length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    if (current === 0) return
    setCurrent(current === 0 ? slides.length - 1 : current - 1)
  }

  const carouselLeftArrowClass = classNames('carousel-left-arrow', {
    disabled: current === 0
  })

  const carouselRightArrowClass = classNames('carousel-right-arrow', {
    disabled: current === slides.length - 1
  })

  const carouselSlideClass = index =>
    classNames('carousel-slide', {
      active: index === current
    })

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null
  }

  return (
    <div className="carousel" style={{ width, height }}>
      <div onClick={prevSlide} className={carouselLeftArrowClass}>
        <LeftArrowIcon />
      </div>
      <div onClick={nextSlide} className={carouselRightArrowClass}>
        <RightArrowIcon />
      </div>
      {slides.map((slide, index) => {
        return (
          <div className={carouselSlideClass(index)} key={index}>
            {index === current && (
              <img src={slide.image} alt="slider" className="carousel-image" width={width} height={height} />
            )}
          </div>
        )
      })}
    </div>
  )
}
