import { useTranslation } from 'react-i18next'
import { usePageTitle } from 'hooks/pageTitle.js'

import PageContent from 'components/Pages/PageContent.js'
import DocumentBanner from 'components/Banner/DocumentBanner/DocumentBanner.js'
import Accordion from 'components/Accordion/Accordion.js'

import bannerImage from 'assets/images/banners/medicomfort-document.png'
import bannerBackgroundImage from 'assets/images/banners/medicomfort-banner-bg.jpg'
import { usePageContents } from '../hooks'

export default function MediComfort() {
  const { t } = useTranslation()
  const { medicomfortPageContent } = usePageContents()

  usePageTitle(t('pages.medicomfort.title'))

  return (
    <article className="medicomfort-page">
      <DocumentBanner
        title={t('pages.medicomfort.title')}
        image={bannerImage}
        background={bannerBackgroundImage}
        link="https://terranteto.hu/wp-content/uploads/2020/12/Terran-Medicomfort-kiadvany_20210501.pdf"
        download="download"
      />
      <PageContent content={medicomfortPageContent[0]} />
      <Accordion items={medicomfortPageContent[1]} />
    </article>
  )
}
