import { useTranslation } from 'react-i18next'
import { usePageTitle, usePageContents } from 'hooks'

import PageContent from 'components/Pages/PageContent.js'
import DocumentBanner from 'components/Banner/DocumentBanner/DocumentBanner.js'
import Accordion from 'components/Accordion/Accordion.js'

import bannerImage from 'assets/images/banners/gutter-document.png'
import bannerBackgroundImage from 'assets/images/banners/gutter-banner-bg.jpg'

export default function Gutter() {
  const { t } = useTranslation()
  const { gutterPageContent } = usePageContents()

  usePageTitle(t('pages.gutter.title'))

  return (
    <article className="gutter-page">
      <DocumentBanner
        title={t('pages.gutter.title')}
        image={bannerImage}
        background={bannerBackgroundImage}
        link="https://terranteto.hu/wp-content/uploads/2017/11/Terran-ereszcsatorna-kiadvany-2020401-1.pdf"
        download="download"
      />
      <PageContent content={gutterPageContent[0]} />
      <Accordion items={gutterPageContent[1]} />
      <PageContent content={gutterPageContent[2]} />
    </article>
  )
}
