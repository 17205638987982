import { useTranslation } from 'react-i18next'

import NodeDrawingsPage from 'pages/NodeDrawings.js'
import GeneronPage from 'pages/Generon.js'
import CoverageLengthPage from 'pages/CoverageLength.js'
import StructuralWidthPage from 'pages/StructuralWidth.js'
import PerformanceDeclarationsPage from 'pages/PerformanceDeclarations.js'
import PublicationsPage from 'pages/Publications.js'
import BaseTilesDimensionsPage from 'pages/BaseTilesDimensions.js'
import VentilationTilesDimensionsPage from 'pages/VentilationTilesDimensions.js'
import FoilSelectorPage from 'pages/FoilSelector.js'
import GutterPage from 'pages/Gutter.js'
import MediComfortPage from 'pages/MediComfort.js'
import ConsultantsPage from 'pages/Consultants.js'

import { usePageContents } from './index.js'

export default function useRoutes() {
  const { t } = useTranslation()
  const {
    nodeDrawingsPageContent,
    generonPageContent,
    coverageLengthContent,
    structuralWidthPageContent,
    performanceDeclarationsPageContent,
    baseTilesPageContent,
    ventilationTilesPageContent,
    foilSelectorPageContent,
    gutterPageContent,
    medicomfortPageContent,
    consultantsPageContent
  } = usePageContents()

  const menuList = [
    {
      id: 1,
      name: 'nodeDrawings',
      title: t('pages.nodeDrawings.title'),
      component: <NodeDrawingsPage />,
      content: nodeDrawingsPageContent
    },
    {
      id: 2,
      name: 'generon',
      title: t('pages.generon.title'),
      component: <GeneronPage />,
      content: generonPageContent
    },
    {
      id: 3,
      name: 'coverageLength',
      title: t('pages.coverageLength.title'),
      component: <CoverageLengthPage />,
      content: coverageLengthContent
    },
    {
      id: 4,
      name: 'structuralWidth',
      title: t('pages.structuralWidth.title'),
      component: <StructuralWidthPage />,
      content: structuralWidthPageContent
    },
    {
      id: 5,
      name: 'performanceDeclarations',
      title: t('pages.performanceDeclarations.title'),
      component: <PerformanceDeclarationsPage />,
      content: performanceDeclarationsPageContent
    },
    {
      id: 6,
      name: 'publications',
      title: t('pages.publications.title'),
      component: <PublicationsPage />,
      content: ''
    },
    {
      id: 8,
      name: 'baseTilesDimensions',
      title: t('pages.baseTilesDimensions.title'),
      component: <BaseTilesDimensionsPage />,
      content: baseTilesPageContent
    },
    {
      id: 9,
      name: 'ventilationTilesDimensions',
      title: t('pages.ventilationTilesDimensions.title'),
      component: <VentilationTilesDimensionsPage />,
      content: ventilationTilesPageContent
    },
    {
      id: 10,
      name: 'foilSelector',
      title: t('pages.foilSelector.title'),
      component: <FoilSelectorPage />,
      content: foilSelectorPageContent
    },
    {
      id: 11,
      name: 'gutter',
      title: t('pages.gutter.title'),
      component: <GutterPage />,
      content: gutterPageContent
    },
    {
      id: 12,
      name: 'medicomfort',
      title: t('pages.medicomfort.title'),
      component: <MediComfortPage />,
      content: medicomfortPageContent
    },
    {
      id: 13,
      name: 'consultants',
      title: t('pages.consultants.title'),
      component: <ConsultantsPage />,
      content: consultantsPageContent
    }
  ]

  return { menuList }
}
